<template>
  <div>
    <b-card>
      <div>
        <b-img
          :src="Item.image"
          class="col-xl-4 rounded"
          style="magin: 10px;"
        />
      </div>
      <div style="margin: 10px;">
        Nazwa: {{ Item.name }}<br>
        Opis: {{ Item.description }}<br>
        Aktualna ilość: {{ Item.quantity }}<br>
      </div>
    </b-card>
    <b-card>
      <button
        v-b-modal.modal-prevent-closing
        class="btn btn-success"
        style="width: 100%; margin-top: 5px;"
      >
        Nowe Przedmioty w magazynie
      </button>
      <button
        class="btn btn-danger"
        style="width: 100%; margin-top: 5px;"
        @click="deleteitem ()"
      >
        Usuń Przedmiot
      </button>
      <router-link :to="{ name: 'Warehouse' }">
        <button
          class="btn btn-primary"
          style="width: 100%; margin-top: 5px;"
        >
          Powrót
        </button>
      </router-link>
    </b-card>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Podaj dane: "
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Ilość"
          label-for="name-input"
          invalid-feedback="Ilość jest wymagana!"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            type="number"
            required
          />
        </b-form-group>
      </form>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BButton, BForm, BFormInput, BFormGroup, BImg,
} from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BImg,
  },
  data() {
    return {
      Item: {
        id: '',
        name: '',
        quantity: '',
        image: '',
      },
      name: '',
      nameState: null,
      submittedNames: [],
    }
  },
  mounted() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}warehouse/${this.$route.params.id}`, config)
      .then(response => {
        this.Item = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    deleteitem() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.delete(`${process.env.VUE_APP_API_URL}warehouse/${this.$route.params.id}`, config)
        .then(response => {
          swal.fire({
            title: 'Usunięto!',
            text: 'Przedmiot został usunięty z magazynu.',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.$router.push({ name: 'Warehouse' })
        })
        .catch(error => {
          console.log(error)
        })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.post(`${process.env.VUE_APP_API_URL}warehouse/add/${this.$route.params.id}/${this.name}`, {}, config)
        .then(response => {
          swal.fire({
            title: 'Dodano!',
            text: 'Przedmiot został dodany do magazynu.',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.$router.push({ name: 'Warehouse' })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style>

</style>
